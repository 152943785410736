@import url(https://use.typekit.net/sri1dce.css);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  outline: none !important;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background: #fff;
  font-size: 18px;
}

.arabicText {
  text-align: right !important;
  direction: rtl;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: edita, serif;
  font-weight: 600;
}

h6 {
  font-size: 14px;
  margin-top: 16px;
}

h1 {
  font-size: 56px;
}

h2 span {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
}

h3 {
  margin: 24px 0 8px;
}

h5 {
  font-style: italic;
}

blockquote {
  font-size: 24px;
  margin-top: 16px;
  font-family: edita, serif;
  font-style: italic;
  margin-bottom: 4px;
}

.quote-source {
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 16px;
}

img {
  max-width: 100%;
}

.caption {
  padding: 12px;
  background: #f8f8f8;
  margin-bottom: 20px;
}

.caption h5 {
  font-weight: 400;
  font-size: 20px;
}

.caption h6 {
  margin-top: 2px;
}

a:hover {
  color: inherit !important;
}

p {
  font-size: 18px;
}

select {
  border: none;
}

.row > * {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.container {
  max-width: 960px;
}

.narrowContainer .container {
  max-width: 700px;
}

.cursor {
  z-index: 9;
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    opacity: 0;
  }
  .cursor__inner {
    fill: none;
    stroke: lime;
    stroke-width: 3px;
  }
}

/* Buttons */

.btn {
  background: none;
  border-radius: 100px;
  font-weight: 600 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 12px 32px;
  color: #000;
  font-family: "Cormorant", serif;
  font-size: 18px;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 40px;
  line-height: 18px;
}

button.nav-link {
  background: none;
  border: none;
  background: none;
  color: inherit;
  margin-right: 4px;
  padding: none;
}

.main-btn {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.btn-upload {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  border: none;
  background: none;
}

.btn-upload i {
  border: 1px solid;
  padding: 32px;
  border-radius: 50px;
  font-size: 32px;
  margin-bottom: 8px;
  transition: all ease-in-out 0.3s;
}

.btn-upload:hover i {
  border-color: #00d300 !important;
  background: #00d300;
  color: #fff;
}

.story-right.main-btn {
  margin-left: 16px;
}

.Storyfooter {
  text-align: center;
  margin-top: 40px;
  margin-top: 30px;
  margin-bottom: 100px;
}

.link-btn {
  border: 1px solid;
}

.story-right {
  float: right;
}

.storyTelling .btn.secondary {
  border: none;
}

.btn:hover {
  background: #00d300;
  font-weight: 500;
  color: #fff;
  border-color: transparent !important;
}

.close-btn {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}

.printerBtn {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 10;
  background: #f9f9f9;
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.editDetailBtn {
  position: absolute;
  top: 16px;
  left: 76px;
  z-index: 10;
  background: #f9f9f9;
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.deleteDetailBtn {
  position: absolute;
  top: 16px;
  left: 136px;
  z-index: 10;
  background: #f9f9f9;
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.listBtn {
  border: none;
  border-radius: 30px;
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000000;
  position: absolute;
  top: 16px;
}

.editListBtn {
  color: #fff;
  background: #000;
  right: 64px;
}

.deleteListBtn {
  right: 16px;
  padding: 10px;
}

.badge {
  border: none;
  border-radius: 30px;
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #c7ffc7;
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
}

.badge.privacy {
  border: none;
  border-radius: 30px;
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
  background: #fff;
  position: absolute;
  top: 60px;
  left: 16px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
}

.title {
  font-size: 10vh;
}

.App {
  width: 100%;
  height: 100%;
  font-weight: 500;
  color: #000;
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.App a {
  color: inherit;
}

/* Top menu */

.top-menu {
  text-align: right;
  margin-bottom: 16px;
  padding-top: 16px;
  z-index: 2;
}

.topMenuActions,
.loggedInMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.authActions {
  margin-right: 8px;
}

.singleAuthaction {
  margin-left: 16px;
  text-decoration: none;
  padding: 0;
}

.languages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.languages button {
  border: none;
  background: none;
  color: inherit;
}

/* Navbar */

.navbar {
  color: #000;
  height: 50px;
  overflow: visible;
  z-index: 3;
  margin-top: 16px;
  font-family: edita, serif;
  font-weight: 600;
}

.logo {
  width: 66px;
  max-width: 66px;
}

a.active {
  position: relative;
}

a.active::before {
  left: 0;
  top: 50%;
  content: "";
  display: block;
  height: 3px;
  width: 100%;
  background: #00ff00;
  position: absolute;
  z-index: -1;
}

.navbar-light.navbar-expand-lg.navbar-light .navbar-nav .nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000 !important;
  line-height: 18px;
  text-transform: capitalize !important;
}

.subMenu.navbar-nav .nav-link {
  font-weight: 200;
}

.navbar-nav .nav-link .lni {
  margin-right: 4px;
  font-size: 12px;
}

/* Hero */

.Hero {
  display: block;
  text-align: left;
  margin-top: -66px;
}

.cls-1 {
  fill: none;
  stroke: #00ff00;
  stroke-miterlimit: 10;
  stroke-width: 0.82px;
}

#stroke {
  height: auto;
  width: 100vw;
}

path {
  transition: stroke-dashoffset 1000ms ease;
}

.animation {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

#lockup,
#stroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.Intro {
  padding: 60px 0 100px;
  text-align: center;
}

#create {
  background-color: #00ff00;
  color: #000;
  border-radius: 100px;
  margin-left: 8px;
}

.subtitle {
  text-align: center;
  z-index: 3;
}

.Footer {
  background: #00ff00;
  color: #000;
  padding: 100px 0;
}
.footerLogo {
  width: 120px;
  margin-bottom: 16px;
}

.footerSection {
  margin-bottom: 16px;
}

/* Create herstory */

.Slate {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f9f9f9;
  display: flex;
}

.storyTelling {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  z-index: 9;
}

.storyTelling .btn {
  border: 1px solid;
}

.storybackBtn {
  margin-left: 16px;
  margin-right: 16px;
}

.storybackBtn.story-right {
  margin-right: 0;
}

.example-appear {
  opacity: 0.01;
  top: 100%;
  bottom: auto;
}

.example-appear.example-appear-active {
  opacity: 1;
  top: 0;
  bottom: 0;
  transition: all 2s ease-in;
}

.single-step {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border-radius: 0px;
  position: relative;
  border: none;
  padding: 32px 16px;
}

.step-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  padding-top: 40px;
}

.step-body > .row {
  height: 100%;
}

.quill {
  /* margin-top: 32px; */
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-container.ql-snow {
  min-height: 200px;
  border-top: 1px solid #ccc !important;
  height: calc(100% - 32px);
}

.Login {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #f9f9f9;
}

.Login .btn {
  background: #000000;
  border: none;
  margin-top: 24px;
}

.Login form div {
  text-align: left;
  margin-top: 8px;
}

.Login form div input {
  border: none;
  width: 100%;
  padding: 8px 16px;
  background: #c7ffc7 !important;
}

.successful {
  display: block;
  background: #00ff00;
  color: #000;
  padding: 16px;
  margin-top: 24px;
  font-size: 16px;
  border-radius: 4px;
}

.success {
  background: #dcffdc;
  padding: 16px;
  margin-top: 16px;
}

.card-body {
  flex: none;
}

.single-step input {
  display: block;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid;
  text-align: center;
  font-size: 32px;
  margin-bottom: 32px;
  margin-top: 24px;
  font-weight: 500;
  font-family: "Cormorant", serif;
  color: #000000;
}

.Progress {
  padding: 16px;
  background: #000000;
  color: #c7ffc7;
  height: 148px;
}

.Progress h2 {
  color: #fff;
  margin-bottom: 0;
}

.Progress p {
  margin-bottom: 0;
  font-style: italic;
  color: #fff;
  opacity: 0.7;
}

/* Images */

.uploadedImages {
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0;
}

.uploadedImage {
  display: flex;
  justify-content: center;
  width: 256px;
  height: 200px;
  margin-bottom: 16px;
}

.removeImage {
  position: absolute;
  background: #ffffff;
  border: none;
  color: #000000;
  font-size: 14px;
  padding: 8px 15px;
  top: 10px;
  right: 12px;
  display: inline-block;
  border-radius: 20px;
}

.removeImage .size-xs {
  font-size: 8px;
}

.uploadedImage {
  position: relative;
  background: #000;
  margin-right: 8px;
  padding: 0;
}

.imageActions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 63px;
  right: 12px;
  justify-content: space-evenly;
}

.imageAction {
  background: #000000;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 8px 12px;
  margin-top: -10px;
  display: inline-block;
  border-radius: 20px;
}

.reorderImage {
  cursor: grab;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.imagePreview {
  background-size: cover;
  margin-right: 8px;
  border-radius: 4px;
}

.imageGrid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.thumb {
  margin-left: 8px;
  opacity: 0.4;
  display: inline-block;
}

.thumb.active {
  opacity: 1;
}

.thumb img {
  border-radius: 4px;
}

/* Prompt */

.writingPrompt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.promptSlider {
  padding: 24px 40px;
  background: #0f0;
  border-radius: 16px;
  position: relative;
  text-align: left;
}

.promptSlider::before {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 37px solid transparent;
  border-right: 46px solid #0f0;
  display: block;
  content: "";
  position: absolute;
  bottom: -10px;
  left: -19px;
  z-index: 999999;
  -webkit-transform: rotate(65deg);
          transform: rotate(65deg);
}

.writingSection {
  height: 100%;
}

.quill {
  height: calc(100% - 170px);
  margin-top: 16px;
}

.persistentActions {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* Single story */

.storyHero {
  padding: 60px;
  text-align: center;
  color: #000;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+72,aecdcc+100 */
  background: #00ff00;
}

h1.storyTitle {
  font-size: 120px;
  line-height: 100px;
  margin-top: 40px;
  z-index: 2;
  position: relative;
}

.StoryHerothumb {
  height: 70vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  margin-top: -40px;
  z-index: 1;
  position: relative;
}

.heroOverlay {
  background: #00ff00;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.2;
}

p.storyExcerpt {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 16px 32px;
  color: #fff;
}

.Backbutton {
  background: none;
  border: none;
  border-radius: 30px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 16px;
  right: 16px;
  color: #000000;
}

.Backbutton.invertedbackButton {
  background: #f9f9f9;
  color: #000000;
}

.Backbutton .size-xs {
  font-size: 12px;
}

.Singlestory {
  padding-top: 0;
}

.storyReview {
  display: flex;
  margin-right: 350px;
}

.storyReviewContainer {
  margin-top: 40px;
}

.Chaptertitle {
  margin-bottom: 40px;
}

.ImageRow {
  margin-bottom: 100px;
  justify-content: center;
}

.TextCol {
  background: #fff;
  color: #000;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ImageRow:nth-child(odd) .TextCol {
  order: 1;
  margin-left: 0;
  z-index: 1;
}

.ImageRow:nth-child(odd) .ImageCol {
  order: 2;
}

.ImageCol img {
  max-width: 600px;
}

.Storymeta {
  text-align: center;
}

.storyAuthor {
  font-style: italic;
  font-family: "Cormorant", serif;
  font-weight: 400;
}

.Storydate {
  font-size: 14px;
  margin-top: 16px;
}

.Chaptertitle {
  text-align: center;
  margin-top: 20px;
}

.add-chapter-button {
  margin-left: 16px;
}

/* Story list */

.Stories {
  padding-top: 60px;
  padding-bottom: 60px;
}

.Storiescontainer {
  margin-top: 32px;
}

.Storylist {
  margin-bottom: 32px;
}

.Storythumb {
  height: 300px;
  width: 100%;
  display: block;
  background-size: cover;
  background-position: center top;
  position: relative;
  z-index: 1;
}

.Storylist a,
.Storiescontainer a {
  text-decoration: none;
  font-style: none;
}

.Storiescontainer a {
  z-index: 9;
  display: block;
}

.Storylist .caption {
  background: #fff;
  margin-top: -32px;
  padding: 16px 24px;
  text-transform: capitalize;
  z-index: 8;
  position: relative;
}

.Storylist .caption h3 {
  display: inline-block;
  background: #00ff00;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
}

.Storylist .caption p {
  display: block;
}

.Storylist .caption span {
  display: block;
}

.Slidenavigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  z-index: 999999;
}

.promptslide {
  margin-top: 4px;
}

.slidenav {
  cursor: pointer;
  background: #00ff00;
  padding: 4px 12px;
  font-family: edita, serif;
  font-weight: 600;
}

.promptslide .slidenav {
  cursor: pointer;
  background: #00ff00;
  padding: 4px 12px;
  font-family: edita, serif;
  font-weight: 600;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  font-size: 24px;
  font-size: 18px;
}

.promptslide .slidenav.prev {
  left: 0;
}

.promptslide .slidenav.next {
  right: 0;
}

.Chapterswiper {
  z-index: 1;
}

[aria-disabled="true"] {
  opacity: 0;
}

.loaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

/* Curated stories */

.Curatedstories {
  background: #f9f9f9;
  padding: 100px 0 40px;
}

.Curatedstories .Storymeta {
  padding-top: 0;
  margin-bottom: 40px;
}

.Singlecurated {
  margin: 32px 0 40px;
}

.Singlecurated .Storythumb {
  height: 300px;
}

.Excerpt {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Additional options */

.additionalOptions {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
}

.icon-btn i.lni {
  margin-right: 8px !important;
  font-size: 16px;
}

/* Prompts */

/* Image actions */

.image-actions {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

/* Annotate */

input.annotationInput {
  background: none;
  font-size: 12px;
  color: #000;
  margin: 0;
  margin-top: -10px;
  border: none;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  text-align: left;
  padding: 4px 6px;
}

.Annotationbutton {
  text-align: left;
  padding: 16px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.rp-default-input-section {
  background: #f9f9f9 !important;
  color: #000000 !important;
  border-radius: 0 !important;
}

.rp-default-input-section input {
  font-size: 16px;
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  min-width: 150px;
  text-align: left;
  color: #000000 !important;
}

.rp-default-input-section input::-webkit-input-placeholder {
  text-transform: capitalize !important;
  color: #000000 !important;
}

.rp-default-input-section input:-ms-input-placeholder {
  text-transform: capitalize !important;
  color: #000000 !important;
}

.rp-default-input-section input::placeholder {
  text-transform: capitalize !important;
  color: #000000 !important;
}

.rp-default-input-section a {
  width: 50px !important;
  background: #fff !important;
  border-radius: 0 !important;
}

.captionDelete {
  width: 22px;
}

/* Story actions */

.story-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-actions {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.circular-btn {
  background: none;
  border: none;
  margin-bottom: 16px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.circular-btn i {
  font-size: 16px;
  border: 1px solid;
  padding: 16px;
  border-radius: 40px;
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
}

/* Record */

input.uploadAudio {
  border: none;
  font-size: 18px;
  border: 1px solid;
  height: 53px;
  display: flex;
  align-self: center;
  margin: 0;
  padding: 8px;
  margin-left: 16px;
  margin-right: 16px;
}

.mediaBtn {
  margin-bottom: 16px;
}

.mediaBtn.record {
  color: rgb(172, 9, 9);
}

.mediaBtn.stop-recording i {
  background: #000;
  color: #fff;
  border-color: #000;
}

.recording {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.audioController {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  right: 0;
  width: calc(100% - 15px) !important;
  box-shadow: none !important;
}

.audioPlayback {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.audioPlayback .circular-btn {
  margin-bottom: 0;
}

.rhap_progress-indicator {
  background: #000000 !important;
  box-shadow: none !important;
}

.rhap_main-controls-button {
  color: #000000 !important;
}

.rhap_progress-bar-show-download {
  background-color: #f9f9f9 !important;
}

.hasAudio {
  padding-bottom: 110px !important;
}

/* Profile */

form input.form-control {
  border-radius: 0;
  padding: 16px;
  border: none;
  margin-bottom: 8px;
  box-shadow: none !important;
  background: #e5ffe5;
}

/* Review */

.review-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.Storypreviewsidebar {
  text-align: left;
  background: #c7ffc7;
  padding: 16px;
  height: 100%;
  width: 350px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  overflow: auto;
}

.moduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}

.reviewModule {
  border-top: 1px solid #95d895;
  padding: 16px 0;
}

.coverpreviewContainer {
  position: relative;
}

.coverPreview {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: #f9f9f9;
  color: #000000;
  font-size: 16px;
  border-radius: 4px;
  overflow: hidden;
}

.coverBtnalt {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -103px;
  margin-top: -26px;
  color: #fff;
}

.coverBtn {
  background: none;
  border: none;
}

.sortableChapter {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  width: 256px;
  height: 200px;
  background: #000000;
  color: #f9f9f9;
  margin: 8px;
}

.editingActions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editSave {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -4px;
  margin-bottom: 8px;
}

.editSave button {
  display: flex;
  flex: 1 1;
  margin: 0 !important;
  justify-content: center;
  padding: 12px 0;
  margin: 4px !important;
}

.mg-r {
  margin-right: 16px;
}

.edit-button {
  background: #f9f9f9;
  margin-top: 16px;
}

.Storypreviewfooter h2 {
  margin-bottom: 16px;
}

.excerpttext {
  width: 100%;
  border: none;
  min-height: 100px;
  padding: 8px 16px;
  border: 1px solid #eee;
  border-radius: 4px;
  color: #000000;
}

.cropFooter {
  margin-top: 16px;
}

.cropFooter button {
  margin-left: 16px;
}

/* Printing */

.printing {
  background-color: #f9f9f9;
}

.printing .swiper-wrapper {
  flex-direction: column !important;
  height: auto !important;
  overflow: auto;
}

.printing .swiper-wrapper .swiper-slide {
  width: 100% !important;
}

.printing .Slidenavigation,
.printing .edit-button,
.printing .explore {
  display: none !important;
}

.printing .ImageCol,
.printing .TextCol {
}

.printing .TextCol {
  margin-bottom: 0 !important;
}

.printing .ImageColchild,
.printing .ImageCol img {
  max-width: 100% !important;
}

.printing .ImageColchild img {
  max-width: 100% !important;
}

/* sharing */

.shareBtns {
  position: absolute;
  top: 16px;
  right: 80px;
  background: #f9f9f9;
  padding: 7px 12px;
  border-radius: 20px;
  width: 160px;
}

.shareBtns p {
  margin-bottom: 8 px;
}

.shareHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  transition: all ease-in-out 0.2s;
}

.shareHeader button {
  background: none;
  border: none;
}

.shareBtns .shareTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #000000;
  flex: 1 1;
}

.shareTitle .lni {
  margin-left: 8px;
}

.closeShare .lni {
  font-size: 16px;
}

.shareList {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
}

.shareBtn.copy {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50px;
  color: #fff;
  background: #000;
}

/* Stepper */

.stepper {
  width: 100%;
  padding: 24px 32px;
  border-radius: 4;
  display: flex;
  justify-content: space-between;
  background: #000;
}

.stepWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.stepper button {
  border: none;
  background: none;
}

.stepper button img {
  height: 50px;
  width: auto !important;
}

.stepper .stepButton {
  background: none;
  font-family: edita, serif;
  margin-bottom: 8px;
  padding: 4px 0;
  font-weight: 600;
  border-bottom: 3px solid;
  color: #fff;
  transition: all ease-in-out 0.3s;
}

.stepper .stepButton:hover {
  color: #00d300;
}

.stepper .saveButton {
  margin-left: 16px;
  color: #00d300;
}

.singleStep {
  border-left: 1px solid #fff;
  padding-left: 8px;
  padding-right: 16px;
}

.editImages {
  margin-top: 16px;
}

/* General pages */

.article-section {
  margin-top: 32px;
}

/* FAQs */

.faqSection {
  margin-top: 32px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 16px;
  border-radius: 8px;
}

.accordion-button {
  font-size: 24px;
  background-color: transparent;
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
}

.accordion-button:not(.collapsed)::after {
  -webkit-filter: saturate(0);
          filter: saturate(0);
}

/* Large Devices, Wide Screens */

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {
  .navbar.navbar-expand-lg.navbar-light {
    background: #fff;
    padding-bottom: 16px;
    height: 70px;
    margin-bottom: 40px;
  }
  .navbar-expand-lg .navbar-collapse {
    background: #fff;
    padding-bottom: 16px;
  }

  .navbar-toggler.collapsed {
    background: none;
  }

  .navbar #create {
    margin-top: 16px;
    margin-left: 0;
    width: 100%;
  }

  .navbar-light .navbar-toggler {
    border: none;
    padding: 0;
    box-shadow: none !important;
    background: none;
  }
}

/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
  .TextCol {
    order: 3 !important;
    margin: 0 !important;
  }
}

/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
  .TextCol {
    order: 3 !important;
    margin: 0 !important;
  }

  .ImageCol img {
    max-width: 100%;
  }
}

/* Custom, iPhone Retina */

@media only screen and (max-width: 320px) {
}

/* Loader */

.la-ball-grid-pulse,
.la-ball-grid-pulse > div {
  position: relative;
  box-sizing: border-box;
}
.la-ball-grid-pulse {
  display: block;
  font-size: 0;
  color: #fff;
}
.la-ball-grid-pulse.la-dark {
  color: #333;
}
.la-ball-grid-pulse > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-ball-grid-pulse {
  width: 36px;
  height: 36px;
}
.la-ball-grid-pulse > div {
  width: 8px;
  height: 8px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.la-ball-grid-pulse > div:nth-child(1) {
  -webkit-animation-duration: 0.65s;
  animation-duration: 0.65s;
  -webkit-animation-delay: 0.03s;
  animation-delay: 0.03s;
}
.la-ball-grid-pulse > div:nth-child(2) {
  -webkit-animation-duration: 1.02s;
  animation-duration: 1.02s;
  -webkit-animation-delay: 0.09s;
  animation-delay: 0.09s;
}
.la-ball-grid-pulse > div:nth-child(3) {
  -webkit-animation-duration: 1.06s;
  animation-duration: 1.06s;
  -webkit-animation-delay: -0.69s;
  animation-delay: -0.69s;
}
.la-ball-grid-pulse > div:nth-child(4) {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: -0.41s;
  animation-delay: -0.41s;
}
.la-ball-grid-pulse > div:nth-child(5) {
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-delay: 0.04s;
  animation-delay: 0.04s;
}
.la-ball-grid-pulse > div:nth-child(6) {
  -webkit-animation-duration: 0.84s;
  animation-duration: 0.84s;
  -webkit-animation-delay: 0.07s;
  animation-delay: 0.07s;
}
.la-ball-grid-pulse > div:nth-child(7) {
  -webkit-animation-duration: 0.68s;
  animation-duration: 0.68s;
  -webkit-animation-delay: -0.66s;
  animation-delay: -0.66s;
}
.la-ball-grid-pulse > div:nth-child(8) {
  -webkit-animation-duration: 0.93s;
  animation-duration: 0.93s;
  -webkit-animation-delay: -0.76s;
  animation-delay: -0.76s;
}
.la-ball-grid-pulse > div:nth-child(9) {
  -webkit-animation-duration: 1.24s;
  animation-duration: 1.24s;
  -webkit-animation-delay: -0.76s;
  animation-delay: -0.76s;
}
.la-ball-grid-pulse.la-sm {
  width: 18px;
  height: 18px;
}
.la-ball-grid-pulse.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 1px;
}
.la-ball-grid-pulse.la-2x {
  width: 72px;
  height: 72px;
}
.la-ball-grid-pulse.la-2x > div {
  width: 16px;
  height: 16px;
  margin: 4px;
}
.la-ball-grid-pulse.la-3x {
  width: 108px;
  height: 108px;
}
.la-ball-grid-pulse.la-3x > div {
  width: 24px;
  height: 24px;
  margin: 6px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ball-grid-pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 0.35;
    -webkit-transform: scale(0.45);
    transform: scale(0.45);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

